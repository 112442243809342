<template>
  <div class="overflow-x-auto border px-4 rounded-lg max-h-96">
    <table class="w-full">
      <thead class="text-grey-500 text-left text-xs select-none">
        <tr class="h-16">
          <th class="w-1/5 sticky top-0 bg-white pl-4 mr-14">
            <div
              class="flex items-center cursor-pointer"
              @click="sortByColumn('prospectLast')">
              <div class="flex-none">Prospect</div>
              <div class="ml-2 h-5 w-5 flex-none">
                <sort-icon :active="activeSort === 'prospectLast'" />
              </div>
            </div>
          </th>
          <th class="w-1/5 sticky top-0 bg-white">
            <div
              class="flex items-center cursor-pointer mr-14"
              @click="sortByColumn('outreachType')">
              <div class="flex-none">Outreach Type</div>
              <div class="ml-2 h-5 w-5 flex-none">
                <sort-icon :active="activeSort === 'outreachType'" />
              </div>
            </div>
          </th>
          <th class="w-1/5 sticky top-0 bg-white">
            <div
              class="flex items-center cursor-pointer mr-14"
              @click="sortByColumn('requestDate', 'date')">
              <div class="flex-none">Requested</div>
              <div class="ml-2 h-5 w-5 flex-none">
                <sort-icon :active="activeSort === 'requestDate'" />
              </div>
            </div>
          </th>
          <th class="w-1/5 sticky top-0 bg-white">
            <div
              class="flex items-center cursor-pointer mr-14"
              @click="sortByColumn('requestDate', 'date')">
              <div class="flex-none">Request Completed</div>
              <div class="ml-2 h-5 w-5 flex-none">
                <sort-icon :active="activeSort === 'requestDate'" />
              </div>
            </div>
          </th>
          <th class="w-1/5 sticky top-0 bg-white">
            <div
              class="flex items-center cursor-pointer mr-14"
              @click="sortByColumn('prospectAction', 'boolean')">
              <div class="flex-none">Prospect Action</div>
              <div class="ml-2 h-5 w-5 flex-none">
                <sort-icon :active="activeSort === 'prospectAction'" />
              </div>
            </div>
          </th>
          <th class="w-1/5 sticky top-0 bg-white"></th>
        </tr>
      </thead>
      <tbody
        class="font-medium text-sm"
        :class="{ tr: !noConversationsToDisplay }">
        <tr
          class="h-14"
          v-for="(outreach, index) of outreachRequests"
          v-bind:key="`outreach-row-${index}`">
          <td class="pr-10 pl-4">
            {{ outreach.prospectFirst }} {{ outreach.prospectLast }}
          </td>
          <td class="pr-10">
            <div
              v-if="outreach.outreachType === 'Email'"
              class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="text-grey h-4 w-4 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              Email
            </div>
            <div
              v-else-if="outreach.outreachType === 'Call'"
              class="flex items-center">
              <img
                :src="require('@/assets/call-icon.svg')"
                class="h-4 w-4 mr-2" />

              Call
            </div>
            <div
              v-else-if="outreach.outreachType === 'Video'"
              class="flex items-center">
              <img
                :src="require('@/assets/video-icon.svg')"
                class="h-4 w-4 mr-2" />

              Video
            </div>
          </td>
          <td class="pr-10">
            <div class="whitespace-nowrap">
              {{ formatDateForDisplay(outreach.requestDate) }}
            </div>
          </td>
          <td class="pr-10">{{ outreach.sent ? "Yes" : "No" }}</td>
          <td class="pr-10">{{ outreach.prospectAction ? "Yes" : "No" }}</td>
          <td>
            <div
              v-if="!outreach.sent"
              class="flex space-x-2 items-center text-blue">
              <p
                class="hover:text-blue-500 cursor-pointer"
                @click="$emit('send-message')">
                Remind
              </p>
              <p v-if="outreach" class="text-grey">|</p>
              <p
                v-if="outreach"
                class="hover:text-blue-500 cursor-pointer"
                @click="$emit('delete-outreach', outreach)">
                Delete
              </p>
            </div>
          </td>
        </tr>

        <!-- Skeleton Loading -->
        <tr
          v-for="(n, index) in 6"
          class="h-14 animate-pulse"
          :class="{
            hidden: outreachRequests.length || noConversationsToDisplay,
          }"
          v-bind:key="`skeleton-row-${index}`">
          <td class="pr-5">
            <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
          </td>
          <td class="pr-5">
            <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
          </td>
          <td class="pr-5">
            <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
          </td>
          <td class="pr-5">
            <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
          </td>
          <td class="pr-5">
            <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
          </td>
          <td class="pr-5">
            <div class="w-2/3 h-4 mx-2 bg-grey-200 rounded-lg" />
          </td>
        </tr>

        <tr
          class="h-14 relative"
          :class="{ hidden: !noConversationsToDisplay }">
          <td>
            <div class="absolute top-0 left-4">
              There are no outreach requests to display for this user.
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import sortService from "@/services/sort.service";
  import dateService from "@/services/date.service";
  export default {
    props: ["rows"],
    data() {
      return {
        outreachRequests: [],
        activeSort: "",
        sortDirection: {},
        noConversationsToDisplay: false,
      };
    },
    methods: {
      sortByColumn(propertyName, dataType = "string") {
        let sortedOutreachRequests;
        this.activeSort = propertyName;

        if (this.sortDirection[propertyName]) {
          this.sortDirection[propertyName] = false;
          sortedOutreachRequests = sortService.sortArrayOfObjects(
            this.outreachRequests,
            propertyName,
            dataType,
            true
          );
        } else {
          this.sortDirection[propertyName] = true;
          sortedOutreachRequests = sortService.sortArrayOfObjects(
            this.outreachRequests,
            propertyName,
            dataType
          );
        }

        this.outreachRequests = sortedOutreachRequests;
      },
      formatDateForDisplay(date) {
        return dateService.toDisplayDate(date);
      },
    },
    watch: {
      rows(updatedOutreachRequests) {
        this.noConversationsToDisplay = !updatedOutreachRequests.length;
        this.outreachRequests = updatedOutreachRequests;
      },
    },
    created() {
      this.outreachRequests = this.rows;
    },
  };
</script>

<style scoped>
  .tr:nth-child(odd) {
    background-color: #d9ebff;
  }
</style>
